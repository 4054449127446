<template>
  <div class="dashboard-container" v-loading="loading">
    <el-row :gutter="20" style="margin-bottom: 10px">
      <el-col :span="24">
        <el-card class="box-card">
          <div slot="header" class="clearfix echart-title">
            <p>新老客户统计</p>
            <chart-timer-picker active-tag="week" :tags="['week', 'month1', 'year1']" @time-change="handleTimeChangeDistForward"/>
          </div>
          <div class="echart-alltitle">
            <div :span="24" id="echarts_right_forward" class="echarts-right"></div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-bottom: 10px">
      <el-col :span="24">
        <el-card class="box-card">
          <div slot="header" class="clearfix echart-title">
            <p>客户群体分析</p>
            <chart-timer-picker active-tag="week" :tags="['week', 'month1', 'year1']" @time-change="handleTimeChangeDistCat"/>
          </div>
          <div class="echart-alltitle">
            <el-row style="width:100%;">
              <el-col :span="12">
                <div :span="24" id="echarts_right_cat" class="echarts-right"></div>
              </el-col>
              <el-col :span="12">
                <div id="echarts_right_shop" class="echarts-right" style="width:700px;"></div>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as API_Sta from "@/api/$sta.trade.js";
import ChartTimerPicker from '../../statistics/chart-timer-picker';
import echartsOptionsMap from '../echartsOptionsMap';
import echartMapChina from '../echart-map-china'

export default {
  components: { ChartTimerPicker },
  data() {
    return {
      loading: false,
      goodsList: null,
      memberList: null,
      /** 基本数据 */
      info: {},
      advancedForm: {
        times: "",
      },
      // 关键词排行
      active: "day",
      activeName: "近7天",
      tableData: [],
      page_no: 1,
      page_size: 10,
    };
  },
  activated() {},
  created() {
    this.$echarts.registerMap('china', echartMapChina)
  },
  mounted() {
    this.$nextTick(() => {
      this.sesalChartForwad = this.$echarts.init(
        document.getElementById("echarts_right_forward")
      );
      this.sesalChartCat = this.$echarts.init(
        document.getElementById("echarts_right_cat")
      );
      this.sesalChartShop = this.$echarts.init(
        document.getElementById("echarts_right_shop")
      );
    });
  },
  methods: {
    handleTimeChangeDistForward({milli, activeName}) {
      this.activeDistForward = 1;
      this.Get_DistForward(milli[0], milli[1], activeName);
    },
    handleTimeChangeDistCat({milli}) {
      this.activeDistCat = 1;
      this.Get_DistCat(milli[0], milli[1]);
      this.Get_DistShop(milli[0], milli[1]);
    },
    handleTimeChangeDistShop({milli}) {
      this.activeDistShop = 1;
      this.Get_DistShop(milli[0], milli[1]);
    },
    Get_DistForward(start, end, name) {
      API_Sta.getCustomerNewOrOld({
        start,
        end
      }).then((res) => {
        this.loading = false;
        const x = res.xAxis;
        const s = res.series;
        const option = {
          backgroundColor: "#fff", // 背景色
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: x,
          },
          yAxis: {
            name: name + "客户数统计",
            type: "value",
            axisLabel: {
              formatter: "{value}",
            },
          },
          legend: {
            orient: "vertical",
            x: "right",
            y: "top",
            data: [s[0].name, s[1].name],
          },
          tooltip: {
            trigger: "axis",
            formatter(params) {
              const [p1, p2] = params
              var time, colora, colorb, a, b, c, d, e, f;
              time = p1.axisValueLabel
              colora = p1.color
              colorb = p2.color
              a = p1.value
              b = p2.value
              // console.log(params, 'params');
              return `<div style="width:100%;height:100;">
                        <div style="display:flex;justify-content:center;align-items:center;padding-top: 8px;">${time}</div>
                        <div style="display:flex;justify-content:space-between;align-items:center;margin-top:10px;">
                          <div style="padding:0 20px;width:50%;display:flex;justify-content:space-between;align-items:center;">
                            <div style="display:flex;justify-content:space-between;align-items:center;">
                              <div style="width:10px;height:10px;border-radius:100%;background-color:${colora};margin-right:5px;"></div>
                              <div>老客户数：</div>
                            </div>
                            <div class="item-value">
                              ${a}
                            </div>
                          </div>
                        </div>

                        <div style="display:flex;justify-content:space-between;align-items:center;margin-top:10px;">
                          <div style="padding:0 20px;width:50%;display:flex;justify-content:space-between;align-items:center;">
                            <div style="display:flex;justify-content:space-between;align-items:center;">
                              <div style="width:10px;height:10px;border-radius:100%;background-color:${colorb};margin-right:5px;"></div>
                              <div>新客户数：</div>
                            </div>
                            <div class="item-value">
                              ${b}
                            </div>
                          </div>
                        </div>
                      </div>`
            },
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          series: [
            {
              name: s[1].name,
              data: s[1].data,
              type: "line",
              smooth: true, // 让曲线变平滑的
              symbol: "none", // 去掉点
            },
            {
              name: s[0].name,
              data: s[0].data,
              type: "line",
              smooth: true, // 让曲线变平滑的
              symbol: "none", // 去掉点
            },
          ],
        };
        this.sesalChartForwad.setOption(option);
      });
    },
    Get_DistCat(start, end) {
      API_Sta.getCustomerSex({
        start,
        end
      }).then((res) => {
        this.loading = false;
        var localName = [];
        const dat = [];
        if (res.length > 0) {
          res.forEach((e, i) => {
            dat.push({
              value: e.sex_sum,
              name: e.vs_type === 1 ? '男' : '女'
            })
          });
          res.forEach((e, i) => {
            localName.push(e.vs_type === 1 ? '男' : '女')
          });
        } else {
          Array.apply(null, {length: 2}).forEach((e, i) => {
            dat.push({
              value: 0,
              name: i === 0 ? '男' : '女'
            })
          });
          Array.apply(null, {length: 2}).forEach((e, i) => {
            localName.push(i === 0 ? '男' : '女')
          });
        }
        console.log(dat, localName, 'sex');
        const option = {
          title: {
            text: '男女比例',
            left: 'center'
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)",
          },
          legend: {
            orient: "vertical",
            right: 10,
            top: 260,
            bottom: 20,
            // data: ["百度小程序", "支付宝小程序", "PC端", "H5", "微信小程序"],
            data: localName
          },
          series: [
            {
              name: "会员来源",
              type: "pie",
              radius: "55%",
              center: ["50%", "60%"],
              // data: [
              //   { value: 0, name: "百度小程序" },
              //   { value: 0, name: "支付宝小程序" },
              //   { value: 0, name: "PC端" },
              //   { value: 0, name: "H5" },
              //   { value: 0, name: "微信小程序" },
              // ],
              data: dat
            },
          ],
        };
        this.sesalChartCat.setOption(option);
      });
    },
    Get_DistShop(start, end) {
      API_Sta.getCustomerArea({
        start,
        end
      }).then((res) => {
        this.loading = false;
        const { data, name } = res
        const _data = data.map((item, index) => ({ name: name[index], value: data[index] }))
        this.sesalChartShop.setOption(echartsOptionsMap({
          titleText: '客户分布情况',
          seriesName: '通过扫码分销商转发的活动页进入小程序的客户人数',
          seriesData: _data
        }))
      });
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
.item-row {
  margin-bottom: 10px;
  .el-col {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
}
.item_list {
  background: #ffffff;
  border-radius: 12px;
  margin: 0 5px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  img {
    margin-top: 22px;
  }
  div {
    span {
      display: block;
    }
    span:nth-child(1) {
      color: #212121;
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 5px;
    }
    span:nth-child(2) {
      font-size: 14px;
      font-weight: bold;
      color: #434343;
    }
  }
  img {
    width: 82px;
    height: 82px;
  }
}
.echart-title::after {
  display: none;
}
.echart-title {
  height: 50px;
  font-size: 18px;
  color: #007f7f;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p:nth-child(2) {
    color: #212121;
    font-size: 14px;
  }
  p span {
    cursor: pointer;
    line-height: 20px;
    height: 24px;
    display: inline-table;
    margin: 0 5px;
  }

  p span.active {
    color: #007f7f;
    border-bottom: 2px solid #007f7f;
  }
}

.echart-alltitle {
  display: flex;
  justify-content: space-around;
}
.echarts-left1 {
  width: 15%;
}
.echarts-left {
  height: 100px;
  width: 100%;
  background: rgba(51, 205, 189, 0.08);
  border-radius: 8px;
  margin-top: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  h2 {
    font-size: 30px;
    font-weight: 400;
    line-height: 0px;
    color: #212121;
  }
  p {
    font-size: 15px;
    line-height: 0px;
    font-weight: 500;
    margin-top: -10px;
    color: #656565;
  }
}
.echarts-left:nth-child(2),
.echarts-left:nth-child(3) {
  background: rgba(253, 85, 87, 0.08);
}
.echarts-right {
  width: 100%;
  height: 550px;
}

.elcard {
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    background: #f3f5f7;
    border-radius: 8px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #212121;
    margin-bottom: 8px;
    padding: 0 5px;
    cursor: pointer;
  }
}

.dashboard-container {
  height: 100%;
  ::v-deep .el-card__body {
    padding: 10px;
  }
  .goods-statistics {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    a {
      display: block;
      margin: 0;
      width: 75%;
      button {
        width: 100%;
      }
    }
  }
  .goods-image {
    width: 50px;
    height: 50px;
  }
}
.el-row {
  position: relative;
  margin-bottom: 20px;
}
.el-col {
  border-radius: 4px;
}
</style>
